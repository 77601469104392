import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logoColor from "../../assets/logo-color.svg";
import chrityBlack from "../../assets/charityBlack.png";
import charityWhite from "../../assets/charitiesWhite.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../Sices/sideBarSlice";

const Sidebars = ({ sendDataToParent }) => {
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  console.log(isOpen, "isOpen");
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location?.pathname;
  const [manageRetailerISOPen, setManageRetailerISOPen] = useState(false);
  const [manageCategoryOpen, setManageCategoryOpen] = useState(false);
  const [manageRetailerMenu, setManageRetailerMenu] = useState("retailerList");
  const [manageCategoryMenu, setManageCategoryMenu] =
    useState("productCategory");
  const manageHandler = (e) => {
    e.preventDefault();
    setManageRetailerISOPen(!manageRetailerISOPen);
    navigate("/manage-retailers");
  };
  const manageCategoryHandler = (e) => {
    e.preventDefault();
    setManageCategoryOpen(!manageCategoryOpen);
    navigate("/manage-categories");
  };

  useEffect(() => {
    if (currentPath === "/manage-retailers") {
      setManageRetailerISOPen(!manageRetailerISOPen);
    }
    if (currentPath === "/manage-categories") {
      setManageCategoryOpen(!manageCategoryOpen);
    }
  }, [currentPath]);

  const ratailerListHandler = (e, data) => {
    e.preventDefault();
    sendDataToParent(data);
    setManageRetailerMenu(data);
  };
  const productCategoryHandler = (e, data) => {
    e.preventDefault();
    sendDataToParent(data);
    setManageCategoryMenu(data);
  };

  return (
    <>
    {isOpen && (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 md:hidden"
        onClick={() => dispatch(toggleSidebar())}
      ></div>
    )}
    {
      !isOpen && <aside
      className="sidebar position-fixed top-0 start-0 vh-100 bg-white text-white shadow-lg"
      style={{
        width: isOpen ? "0px" :"19%", // Expands when open
        minWidth: isOpen ? "0px" : "250px", // Prevents it from being too small
        transition: "width 0.3s ease-in-out",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        zIndex: 1050,
      }}
      // clme={`sidebar ${isOpen ? "col-8" : "col-8"}`}
    >
      {/* {
        isOpen ? "hii":"bye"
      } */}
    
          <div className="mb-4">
            <a href="" className="logo">
              <img src={logoColor} alt="logo" className="img-fluid" />
            </a>
          </div>
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/dashboard" ? "" : "collapsed"
                }`}
                to={"/dashboard"}
              >
                <i className="bi bi-house-fill"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/manage-customer" ? "" : "collapsed"
                }`}
                to={"/manage-customer"}
              >
                <i className="bi bi-people-fill"></i>
                <span>Manage Customers</span>
              </Link>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/manage-retailers" ? "" : "collapsed"
                }`}
                data-bs-target="#icons-nav"
                onClick={manageHandler}
              >
                <i className="bi bi-shop"></i>
                <span>Manage Retailers</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="icons-nav"
                className={`nav-content collapse ${manageRetailerISOPen && "show"}`}
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a
                    className={`${
                      manageRetailerMenu === "retailerList" && "active"
                    }`}
                    onClick={(e) => ratailerListHandler(e, "retailerList")}
                  >
                    <i className="bi bi-circle"></i>
                    <span>Retailer List</span>
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      manageRetailerMenu === "retailerpending" && "active"
                    }`}
                    onClick={(e) => ratailerListHandler(e, "retailerpending")}
                  >
                    <i className="bi bi-circle"></i>
                    <span>Retailer Pending</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/manage-categories" ? "" : "collapsed"
                }`}
                data-bs-target="#icons-nav"
                onClick={manageCategoryHandler}
              >
                <i className="bi bi-stack"></i>
                <span>Manage Categories</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="icons-nav"
                className={`nav-content collapse ${manageCategoryOpen && "show"}`}
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a
                    className={`${
                      manageCategoryMenu === "productCategory" && "active"
                    }`}
                    onClick={(e) => productCategoryHandler(e, "productCategory")}
                  >
                    <i className="bi bi-circle"></i>
                    <span>Product Category</span>
                  </a>
                </li>
                {/* <li>
                  <a
                    className={`${
                      manageCategoryMenu === "subCategory" && "active"
                    }`}
                    onClick={(e) => productCategoryHandler(e, "subCategory")}
                  >
                    <i className="bi bi-circle"></i>
                    <span>Sub Category</span>
                  </a>
                </li> */}
              </ul>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/sale-fee-management" ? "" : "collapsed"
                }`}
                to={"/sale-fee-management"}
              >
                <i className="bi bi-cash-stack"></i>
                <span>Sales Fee Management</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/order-management" ? "" : "collapsed"
                }`}
                to={"/order-management"}
              >
                <i className="bi bi-handbag-fill"></i>
                <span>Order Management</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/manage-transactions" ? "" : "collapsed"
                }`}
                to={"/manage-transactions"}
              >
                <i className="bi bi-arrow-down-up"></i>
                <span>Manage Transaction</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/charity" ? "" : "collapsed"
                }`}
                to={"/charity"}
              >
                <img src={currentPath === "/charity"?charityWhite:chrityBlack} height="20" className="charity-image" />
                <span>Charity</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/banner" ? "" : "collapsed"
                }`}
                to={"/banner"}
              >
                <i className="bi bi-image"></i>
                <span>Banner</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/user-support" ? "" : "collapsed"
                }`}
                to={"/user-support"}
              >
                <i className="bi bi-headset"></i>
                <span>User Support</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/promo-code" ? "" : "collapsed"
                }`}
                to={"/promo-code"}
              >
                <i className="bi-cash-coin"></i>
                <span>Promo Code</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/faq" || currentPath === "/add-faq"
                    ? ""
                    : "collapsed"
                }`}
                to={"/faq"}
              >
                <i className="bi bi-question-square-fill"></i>
                <span>FAQ's</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/term-and-condition" ? "" : "collapsed"
                }`}
                to={"/term-and-condition"}
              >
                <i className="bi bi-file-earmark-text-fill"></i>
                <span>Terms & Conditions</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/about-us" ? "" : "collapsed"
                }`}
                to={"/about-us"}
              >
                <i className="bi bi-info-square-fill"></i>
                <span>About us</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPath === "/privacy-and-policy" ? "" : "collapsed"
                }`}
                to={"/privacy-and-policy"}
              >
                <i className="bi bi-shield-lock-fill"></i>
                <span>Privacy Policy</span>
              </Link>
            </li>
          </ul>
        </aside>
    }
    
    </>
  );
};

export default Sidebars;
