import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const staticContentApi = createApi({
    reducerPath: "staticContentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: GetUrl.API_URL,
    }),
    endpoints: (builder) => ({
        GetStaticContentByType: builder.query({
            query: (type) => ({
                url: `/admin/get-static-content?type=${type}`,
                method: "GET",
            }),
        }),
        UpdateStaticContent: builder.mutation({
            query: ({ id, data }) => ({
                url: `/admin/update-static-content/${id}`,
                method: "PUT",
                body: data,
                headers: {
                    authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
                },

            }),
        }),

    }),
});

export const {
    useUpdateStaticContentMutation,
    useGetStaticContentByTypeQuery,
} = staticContentApi;
