import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import profileImage from "../../assets/dummy-img.webp";
import LogoutAccount from "../LogoutAccount";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../Sices/sideBarSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState(localStorage.getItem("fullNameAdmin"));
  const [showLogout, setShowLogout] = useState(false);
  const signOutHandler = (e) => {
    e.preventDefault();
    setShowLogout(true);
  };
  const handleClose = () => {
    setShowLogout(false);
  };
  return (
    <main id="main" className="main mt-0 pt-0 px-0">
      <header id="header" className="header d-flex align-items-center">
        {/* <button 
        className="text-xl p-2 rounded-md focus:outline-none md:hidden"
        
      > */}
        {/* <i  onClick={() => dispatch(toggleSidebar())} className="bi bi-filter-left toggle-sidebar-btn"></i> */}
        {/* </button> */}
        <div className="search-bar">
          <form
            className="search-form d-flex align-items-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
        {showLogout && (
          <LogoutAccount showLogout={showLogout} handleClose={handleClose} />
        )}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-xl-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i
                  onClick={() => dispatch(toggleSidebar())}
                  className="bi bi-filter-left toggle-sidebar-btn"
                ></i>
              </a>
            </li>
            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search"></i>
              </a>
            </li>
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-circle"
                />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  Alfred
                </span>
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={profileImage}
                      alt="Profile"
                      className="rounded-circle "
                      style={{ height: "50px", width: "50px" }}
                    />
                    <div className="d-flex flex-column">
                      <h6>Alfred</h6>
                      <span>Admin</span>
                    </div>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* <li>
                      <a className="dropdown-item d-flex align-items-center" href="">
                      <i className="bi bi-person"></i>
                      <span>My Profile</span>
                      </a>
                   </li> */}
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    onClick={signOutHandler}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </main>
  );
};

export default Header;
