import React, { useEffect, useState } from 'react';
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { toast } from "react-toastify";
import { useGetStaticContentByTypeQuery, useUpdateStaticContentMutation } from '../../Services/StaticContentApi';

const TermAndCondition = () => {
   const [UpdateStaticContent] = useUpdateStaticContentMutation();
   const { data: termsAndConditions, refetch } = useGetStaticContentByTypeQuery("termsAndConditions");
   const [inputField, setInputField] = useState({
      id: "",
      title: "",
      content: "",
   });


   useEffect(() => {
      refetch()
   }, [refetch]);

   useEffect(() => {
      setInputField({
         id: termsAndConditions?.data?.[0]?._id || "",
         title: termsAndConditions?.data?.[0]?.title || "",
         content: termsAndConditions?.data?.[0]?.description || "",
      });
   }, [termsAndConditions]);

   const submitHandler = (e) => {
      e.preventDefault();
      UpdateStaticContent({
         id: inputField.id,
         data: {
            title: inputField.title,
            description: inputField.content
         },
      })
         .unwrap()
         .then((res) => {
            toast.success(res?.message);
         })
         .catch((err) => {
            toast.error(err?.data?.message);
         });
   }
   const inputChangeHandler = (e) => {
      setInputField({ ...inputField, [e.target.name]: e.target.value });
   }

   return (
      <>
         <Sidebars />
         <Header />
         <main id="main" className="main mt-0 h-100 min-vh-80">
            <div className="pagetitle">
               <h1>Terms & Condtions</h1>
               <nav>
                  <ol className="breadcrumb">
                     <li className="breadcrumb-item"><a href="/">Home</a></li>
                     <li className="breadcrumb-item active">Terms & Condtions</li>
                  </ol>
               </nav>
            </div>


            <div className="container mt-3">
               <div className="row">
                  <div className="col-md-12 px-0">
                     <div className="card border-0 table-card">
                        <div className="card-body p-4">
                           <form className="form-login n">

                              <div className="mb-4">
                                 <label className="form-label">Title</label>
                                 <input type="text"
                                    className="form-control"
                                    placeholder="Enter Title"
                                    name="title"
                                    value={inputField.title}
                                    onChange={inputChangeHandler}
                                 />
                              </div>
                              <div className="mb-4">
                                 <label className="form-label">Terms & Condtions</label>
                                 <textarea
                                    id="" cols="30"
                                    rows="10"
                                    className="form-control"
                                    placeholder="Enter Terms & Conditions"
                                    name="content"
                                    value={inputField.content}
                                    onChange={inputChangeHandler}
                                 ></textarea>
                              </div>
                              <button type="submit"
                                 onClick={submitHandler}
                                 className="btn bg-button py-33 text-white">Update</button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </main>
         <Footer />
      </>
   )
}

export default TermAndCondition