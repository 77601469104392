import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { toast } from "react-toastify";
import {
  useGetStaticContentByTypeQuery,
  useUpdateStaticContentMutation,
} from "../../Services/StaticContentApi";

const AboutUs = () => {
  const [UpdateStaticContent] = useUpdateStaticContentMutation();
  const { data: aboutUs, refetch } = useGetStaticContentByTypeQuery("aboutus");
  const [inputField, setInputField] = useState({
    id: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setInputField({
      id: aboutUs?.data?.[0]?._id || "",
      title: aboutUs?.data?.[0]?.title || "",
      content: aboutUs?.data?.[0]?.description || "",
    });
  }, [aboutUs]);

  const submitHandler = (e) => {
    e.preventDefault();
    UpdateStaticContent({
      id: inputField.id,
      data: {
        title: inputField.title,
        description: inputField.content,
      },
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" className="main mt-0 h-100 min-vh-80">
        <div className="pagetitle">
          <h1>About Us</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">About Us</li>
            </ol>
          </nav>
        </div>

        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="card border-0 table-card">
                <div className="card-body p-4">
                  <form className="form-login n">
                    <div className="mb-4">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        name="title"
                        value={inputField.title}
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label">About us</label>
                      <textarea
                        id=""
                        cols="30"
                        rows="10"
                        className="form-control"
                        placeholder="Enter Description"
                        name="content"
                        value={inputField.content}
                        onChange={inputChangeHandler}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      onClick={submitHandler}
                      className="btn bg-button py-33 text-white"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;
