import React, { useEffect, useState } from 'react'
import { GetUrl } from '../../../config/GetUrl'
import axios from 'axios'
import { RxDragHandleVertical } from 'react-icons/rx'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate'


const RetailerPending = () => {


    const BASE_URL = GetUrl.API_URL
    const [retailerList, setRetailerList] = useState(['']);
    const [flag,setFlag] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [paginated, setPaginated] = useState([])
        const [page, setPage] = useState(0)
        const [offset, setOffset] = useState(0)
          const [pageCount, setPageCount] = useState(0)
            const [search, setSearch] = useState('')



    useEffect(()=>{
        const fetchdata = async()=>{
            let data = await axios.get(`${BASE_URL}/admin/get-pending-retailers`)
            console.log(data,"data")
            setRetailerList(data?.data?.data);
            setPageCount(data?.data?.data?.length / itemsPerPage)
        }

        fetchdata()
       

},[flag])

console.log(retailerList,"retailerList");

const handleVerification = async (id) => {
    try {
        const token = localStorage.getItem("tokenAdmin"); // Retrieve the token (modify based on your storage method)

        const response = await axios.post(
            `${BASE_URL}/admin/verifiedByAdmin`,
            { id },
            {
                headers: {
                    Authorization: `Bearer ${token}`, // Sending token in Authorization header
                },
            }
        );

        console.log(response,"response")

        if (response?.data?.success ==true) {
            toast.success(response?.data?.message);
            setFlag(!flag);
        }
    } catch (error) {
        console.error("Error verifying:", error);
        
    }
};

const handlePageClick = (e) => {
    setPage(e.selected)
    const newOffset = (e.selected * itemsPerPage);
    setOffset(newOffset)
}

const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search?.length > 0) {
            let searchArr = retailerList.filter((item) => item.fullName?.toLowerCase().includes(search?.toLowerCase()))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr?.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(retailerList?.length / itemsPerPage))
            paginated = retailerList?.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    useEffect(() => {
        paginationHandler()
    }, [retailerList, offset, search]);


    return (
        <>
            <main id="main" class="main mt-0 h-100 min-vh-80">
                <div class="pagetitle">
                    <h1>Manage Retailers</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="">Home</a></li>
                            <li class="breadcrumb-item active">Manage Retailers</li>
                        </ol>
                    </nav>
                </div>
                <div class="container-fluid mt-4 pt-2">
                    <div class="row">
                        <div class="col-md-12 px-0">
                            <h6 class="text-color fw-bolder mb-3">Retailers Lists</h6>
                            <div class="clearfix mb-4 mt-2">
                                <div class="float-start">
                                    <div class="search-bar mt-1">
                                        <form class="d-flex" role="search">
                                            <div class="border-rounded-15">
                                                <div class="input-group">
                                                    <input type="text" class="form-control border-0 bg-none" placeholder="Search..." />
                                                    <span class="input-group-text bg-none border-0">
                                                        <i class="b- bi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="float-end">
                                    <button class="btn btn-main me-2"><i class="bi bi-plus-lg me-2"></i>Add Retailers</button>
                                </div>
                            </div>
                            <div class="card border-0 table-card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped mb-0 align-middle common-table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S no.</th>
                                                    <th scope="col">Retailer Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Registration Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    paginated && paginated?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                                                                <td>{item?.fullName}</td>
                                                                <td>{item?.email}</td>
                                                                <td>{item?.mobileNumber}</td>
                                                                <td>{item?.createdAt}</td>
                                                                <td> <button class="btn btn-main "  onClick={()=>handleVerification(item?._id)}>{item?.isVerifiedByAdmin==false && "Verify Retailer"}</button></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {/* <tr>
                                                    <th scope="row">1</th>
                                                    <td>Mark</td>
                                                    <td>mark@gmail.com</td>
                                                    <td>76754345454453</td>
                                                    <td>12 April 2024</td>
                                                    <td> <span class="status">Pending</span></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        {paginated?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {retailerList?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>
        </>
    )
}

export default RetailerPending