import React,{useState} from 'react'
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import RetailerList from './retailerList/RetailerList'
import RetailerPending from './retailerPending/RetailerPending'
import Footer from '../../components/footer/Footer'

const ManageRetailers = () => {
    const [childData, setChildData] = useState('retailerList');
    


    const handleChildData = (data) => {
      setChildData(data);
    };
    return (
        <>
            <Sidebars sendDataToParent={handleChildData} />
            <Header />         
            {childData === 'retailerList' ? <RetailerList />:<RetailerPending />}
            <Footer />
        </>
    )
}

export default ManageRetailers